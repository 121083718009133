@use 'styles/colors' as *;
@use 'styles/variables' as *;

.ModalLayout {
  padding: 32px;
  background-color: $secondary-D3;
  width: 440px;
  box-shadow: $shadow-popup;
  position: relative;

  &Heading {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 12px;
  }

  &FormControl {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;

    &__label {
      font-size: 14px;
      line-height: 20px;
      font-weight: 700;
      margin-bottom: 4px;
      display: flex;

      span.required-mark {
        color: $red-text;
        cursor: help;
        padding: 4px;
      }
    }

    input,
    .input {
      width: 100%;
    }

    &Error {
      color: $red-text;
      font-size: 12px;
      font-weight: 600;
      margin-top: 4px;
    }

    &.error {
      input,
      .input {
        border-color: $red-text;
      }

      .ModalLayoutFormControl__label {
        color: $red-text;
      }
    }
  }

  &Buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    margin-top: 24px;
  }
  &Description {
    color: $secondary-L3;
  }

  &CloseButton {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }
}
