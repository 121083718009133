@use 'styles/colors' as *;
@use 'styles/mixins' as *;
@use 'styles/variables' as *;

.Login {
  .ModalLayout {
    min-width: 440px;
    padding: 32px 40px;
    box-shadow: $shadow-dropdown;

    .info-box {
      background-color: $secondary-D2;
      display: flex;
      gap: 8px;
      padding: 8px;
      margin-bottom: 8px;
    }

    .support {
      display: flex;
      align-items: baseline;
      gap: 4px;

      span {
        color: $secondary-L3;
      }
    }

    .logo {
      display: block;
      margin: 0 auto;
      margin-bottom: 16px;
    }

    button {
      width: 100%;
    }

    a {
      text-decoration: none;
      button {
        width: auto;
        padding: 0;
      }
    }

    .alternative-section {
      margin-top: 16px;
      @include flex-col(8px);
    }

    .ModalLayoutFormControl {
      margin-bottom: 16px;
    }
  }
}
