.PasswordInput {
  display: flex;
  position: relative;

  input {
    padding-right: 32px;
  }

  .Button {
    position: absolute;
    right: 4px;
    top: 4px;

    &:focus {
      outline: none;
    }

    .Icon {
      display: block;
    }
  }
}
