@use 'styles/colors' as *;
@use 'styles/mixins' as *;
@use 'styles/variables' as *;

.SignUp {
  .ModalLayout {
    min-width: 440px;
    padding: 32px 40px;
    box-shadow: $shadow-dropdown;

    & > img {
      display: block;
      margin: 0 auto;
      margin-bottom: 16px;
    }

    .label-element {
      @include flex(4px);
      align-items: center;
    }

    #register-button {
      width: 100%;
    }

    .alternative-section {
      margin-top: 16px;
      @include flex-col(8px);
    }

    .license-input {
      display: flex;
    }

    .ModalLayoutFormControl {
      margin-bottom: 16px;
    }
  }
}
