@use 'styles/colors' as *;

.LabeledDivider {
  display: flex;
  align-items: center;
  gap: 16px;

  &__line {
    flex-grow: 1;
    height: 1px;
    background-color: $secondary;
  }

  &__text {
    color: $secondary-L3;
  }
}
